import React from 'react';
import { graphql } from 'gatsby';

//Components
import Layout from '../components/layout';
import SEO from '../components/seo';

//Template
import HomepageTemplate from '../templates/pages/homepage';

const IndexPage = ({ data, pageContext }) => {
  return (
    <Layout lang={pageContext.langKey}>
      <SEO
        title={data.pagesJson.languages.en.metaData.title}
        description={data.pagesJson.languages.en.metaData.description}
      />

      <HomepageTemplate data={data.pagesJson.languages.en} lang="en" />
    </Layout>
  );
};

export const HOMEPAGE_EN_QUERY = graphql`
  query HOMEPAGE_EN_QUERY {
    pagesJson(pageKey: { eq: "homepage" }) {
      languages {
        en {
          metaData {
            title
            description
          }
          intro {
            title
            text
            media {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            thumbnailVideo
            fullVideo
          }
          about {
            title
            entry
            image {
              childImageSharp {
                fluid(maxWidth: 936, maxHeight: 624) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
